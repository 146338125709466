<template>
  <a href="https://clustrmaps.com/site/1c3qp" title="ClustrMaps"><img src="//www.clustrmaps.com/map_v2.png?d=j959yb_kzmXkkoL9cSbwyG0GROnIPy5BAQVJA_8AMkk&cl=ffffff"></a>
</template>

<script>
export default {
  name: 'FootMap'
}
</script>

<style scoped>

</style>
